<template>
  <div>
    <div class="p-1">
      <div class="">

        <CRow>
          <CCol sm="6" lg="6">
            <CWidgetDropdown color="primary" :header="total_keluarga" text="Jumlah Keluarga Beresiko Stunting">
              <template #default>
                <CDropdown
                  color="transparent p-0"
                  placement="bottom-end"
                >
                  <template #toggler-content>
                    <CIcon name="cil-settings"/>
                  </template>
                  <CDropdownItem>Action</CDropdownItem>
                  <CDropdownItem>Another action</CDropdownItem>
                  <CDropdownItem>Something else here...</CDropdownItem>
                  <CDropdownItem disabled>Disabled action</CDropdownItem>
                </CDropdown>
              </template>
              <template #footer>
                <CChartLineSimple
                  pointed
                  class="mt-3 mx-3"
                  style="height:70px"
                  :data-points="[65, 59, 84, 84, 51, 55, 40]"
                  point-hover-background-color="primary"
                  label="Members"
                  labels="months"
                />
              </template>
            </CWidgetDropdown>
          </CCol>
          <CCol sm="6" lg="6">
            <CWidgetDropdown color="info" :header="total_anak" text="Jumlah Anak Stunting (Pendek & Sangat Pendek)">
              <template #default>
                <CDropdown
                  color="transparent p-0"
                  placement="bottom-end"
                  :caret="false"
                >
                  <template #toggler-content>
                    <CIcon name="cil-location-pin"/>
                  </template>
                  <CDropdownItem>Action</CDropdownItem>
                  <CDropdownItem>Another action</CDropdownItem>
                  <CDropdownItem>Something else here...</CDropdownItem>
                  <CDropdownItem disabled>Disabled action</CDropdownItem>
                </CDropdown>
              </template>
              <template #footer>
                <CChartLineSimple
                  pointed
                  class="mt-3 mx-3"
                  style="height:70px"
                  :data-points="[1, 18, 9, 17, 34, 22, 11]"
                  point-hover-background-color="info"
                  :options="{ elements: { line: { tension: 0.00001 }}}"
                  label="Members"
                  labels="months"
                />
              </template>
            </CWidgetDropdown>
          </CCol>
        </CRow>

        <div class="row">
          <div class="col-md-12">
            <div class="card border-top rounded shadow p-3">
              <p class="p-0 m-0"><b> Jumlah Anak Stunting (Pendek & Sangat Pendek) Berdasarkan Kelurahan/Desa</b></p>
              <hr />
              <CChartBarExample />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card border-top rounded shadow p-3">
              <p class="p-0 m-0"><b> Sebaran Kasus Stunting Berdasarkan Kelurahan/Desa </b></p>
              <hr />
              <iframe src="https://webgis.stunting.allinstudio.id" title="description" height="700"></iframe>
            </div>
          </div>
        </div>
      </div>

      <!-- <CTabs variant="pills" :active-tab="0">
        <CTab title="Data Stunting">
         
        </CTab>
      </CTabs> -->
    </div>
  </div>
</template>


<script>
import axios from "axios";
import CChartBarExample from "../charts/CChartBarExample.vue";
import { CChartLineSimple } from '../charts/index.js'

export default {
  components: {
    CChartBarExample,
    CChartLineSimple
    
  },
  data() {
    return {
      user: {},
      updateModal: false,
      form: {},
      agenda: [],
      roles: [],
      rooms: [],
      inbox: [],
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 3,
      },
      total_keluarga: 0,
      total_anak: 0
    };
  },
  methods: {
    getData() {
      var loading = this.$loading.show();
      axios
        .get("/agenda", { params: this.params })
        .then((resp) => {
          this.agenda = resp.data.data.data;

          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    getTotalData() {
      this.$store
        .dispatch("stunting/total")
        .then((resp) => {
          this.total_keluarga = resp.total_keluarga.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          this.total_anak = resp.total_anak.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data role \n", e);
        });
    },
    editUser() {
      this.form = this.user;
      this.updateModal = true;
    },
    update() {
      var loading = this.$loading.show();
      if (this.form.password == null) {
        this.$store
          .dispatch("user/updateUser", { id: this.form.id, data: this.form })
          .then(() => {
            this.$toast.success("Berhasil merubah data user");
            loading.hide();
            this.updateModal = false;
            this.form = {};
            this.getMe();
          })
          .catch((e) => {
            this.$toast.error(e);
            this.updateModal = false;
            loading.hide();
          });
      } else {
        this.$store
          .dispatch("user/changePassword", {
            id: this.form.id,
            password: this.form.password,
          })
          .then(() => {
            this.$store
              .dispatch("user/updateUser", {
                id: this.form.id,
                data: this.form,
              })
              .then(() => {
                this.$toast.success("Berhasil merubah data user");
                loading.hide();
                this.updateModal = false;
                this.form = {};
                this.getMe();
              })
              .catch((e) => {
                this.$toast.error(e);
                this.updateModal = false;
                loading.hide();
              });
          })
          .catch((e) => {
            this.$toast.error(e);
            this.updateModal = false;
            loading.hide();
          });
      }
    },
    getMe() {
      this.$store
        .dispatch("auth/me")
        .then((resp) => {
          this.user = resp.data;
          this.getInbox();
          this.getRooms();
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    getRooms() {
      var params = {
        sortby: "id",
        sorttype: "desc",
        row: 3,
        role_id: this.user.role.id,
      };
      this.$store
        .dispatch("room/getRoom", params)
        .then((resp) => {

          this.rooms = resp.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    getInbox() {
      var _params = {
        sortby: "id",
        sorttype: "desc",
        row: 3,
      };
      this.$store
        .dispatch("message/getInbox", {
          id: this.user.role.id,
          params: _params,
        })
        .then((resp) => {
          this.inbox = resp.data.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    getRole() {
      let _params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
      };
      this.$store
        .dispatch("role/getRole", _params)
        .then((resp) => {
          this.roles = resp.data.data;
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data role \n", e);
        });
    },
  },
  mounted() {
    // this.getMe();
    // this.getData();
    // this.getRole();
    this.getTotalData();
  },

  computed: {
    computedRole() {
      return this.roles.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    },
  },
};
</script>

<style scoped>
.border-top {
  border-top: 3px solid #1d4374 !important;
}

.scroll {
  overflow-y: auto;
  height: 150px;
}


</style>

<style>
table {
  display: table;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.tab-content {
  margin-top: 20px !important;
}
</style>